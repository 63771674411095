import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})

export class MediaConfigurationService {

  private config = {
    mediaInit: {
      sizes: {
        article: [
          {name: 'i_1920x1280', width: 1920, height: 1280},
          {name: 'i_1200x675', width: 1200, height: 675},
          {name: 'i_1200x630', width: 1200, height: 630},
          {name: 'i_747x498', width: 747, height: 498},
          {name: 'i_378x252', width: 378, height: 252},
          {name: 'i_272x182', width: 272, height: 182},
          {name: 'thumbnail', width: 378, height: 252},
          {name: 'default', width: 1920, height: 1280},
          {name: 'google', width: 1200, height: 630},
          {name: 'facebook', width: 1200, height: 630},
          {name: 'twitter', width: 1200, height: 675},
        ],
        module: [
          {name: 'i_1920x1280', width: 1920, height: 1280},
          {name: 'i_1200x675', width: 1200, height: 675},
          {name: 'i_1200x630', width: 1200, height: 630},
          {name: 'i_747x498', width: 747, height: 498},
          {name: 'i_378x252', width: 378, height: 252},
          {name: 'i_272x182', width: 272, height: 182},
          {name: 'thumbnail', width: 378, height: 252},
          {name: 'default', width: 1920, height: 1280},
          {name: 'google', width: 1200, height: 630},
          {name: 'facebook', width: 1200, height: 630},
          {name: 'twitter', width: 1200, height: 675},
        ],
        link: [
          {name: 'i_768x512', width: 768, height: 512},
          {name: 'i_375x250', width: 375, height: 250},
          {name: 'i_192x128', width: 192, height: 128},
          {name: 'thumbnail', width: 378, height: 252},
          {name: 'default', width: 1920, height: 1280},
          {name: 'google', width: 1200, height: 630},
          {name: 'facebook', width: 1200, height: 630},
          {name: 'twitter', width: 1200, height: 675},
        ],
      },
    },
  };

  public getConfig(name: string): any {
    if (!name || (name && !this.config[name])) {
      return null;
    }

    return this.config[name];
  }
}
