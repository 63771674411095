import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppLayoutComponent } from './@shared/layouts/app-layout/app-layout.component';
import { NotFoundComponent } from './@shared/layouts/not-found-layout/not-found.component';


const routes: Routes = [
    {
        path: '',
        component: AppLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: () => import('./@modules/homepage/homepage.module').then(m => m.HomepageModule)
            },
            {
                path: 'contact',
                loadChildren: () => import('./@modules/contact/contact.module').then(m => m.ContactModule)
            },
            {
                path: 'about',
                loadChildren: () => import('./@modules/about/about.module').then(m => m.AboutModule)
            },
            {
                path: '**',
                component: NotFoundComponent,
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        initialNavigation: 'enabledBlocking',
        relativeLinkResolution: 'legacy',
        onSameUrlNavigation: 'reload'
    })],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
