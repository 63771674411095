import { Component, HostListener, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
    selector: 'app-header-desktop',
    templateUrl: './header-desktop.component.html',
    styleUrls: ['./header-desktop.component.scss']
})
export class HeaderDesktopComponent implements OnInit {

    semaphores: any = {
        menuShrinked: false,
    };

    @HostListener('window:scroll') onWindowScroll(): void {
        const header = this.document.querySelector('#header_desktop') as HTMLElement;
        const logo = this.document.querySelector('#origami_logo_desktop') as HTMLElement;
        if (this.document.documentElement.scrollTop >= 180 && header.classList.contains('py-12') && logo) {
            this.shrinkHeader(header, logo);
        } else if (this.document.documentElement.scrollTop < 180 && header.classList.contains('py-2') && logo) {
            this.unshrinkHeader(header, logo);
        }
    }

    constructor(
        @Inject(DOCUMENT) private document: Document,
        @Inject(PLATFORM_ID) private platformId) {
    }

    ngOnInit(): void {
    }

    shrinkHeader(header: HTMLElement, logo: HTMLElement): void {
        header.classList.remove('py-12');
        header.classList.add('py-2');
        logo.style.width = '122px';
        logo.style.height = '58px';
        this.semaphores.menuShrinked = true;
    }

    unshrinkHeader(header: HTMLElement, logo: HTMLElement): void {
        header.classList.remove('py-2');
        header.classList.add('py-12');
        logo.style.width = '182px';
        logo.style.height = '84px';
        this.semaphores.menuShrinked = false;
    }

}
