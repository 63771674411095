<div class="w-full bg-gray-700 pt-9 pb-42 sm:py-26">
    <div class="container flex flex-col sm:flex-row sm:justify-between">
        <div class="flex flex-col md:flex-row md:space-x-26">
            <a routerLink="/">
                <img src="assets/img/logos/footer_logo.png"
                     width="456"
                     height="211"
                     class="w-[228px] h-[105px]"
                     alt="Origami Consulting">
            </a>

            <div class="flex flex-col space-y-1 items-start text-white text-16 leading-200 font-700 mt-12 md:mt-0">
                <a routerLink="/" class="underline">Home</a>
                <a routerLink="/about" class="underline">About</a>
                <a routerLink="/contact" class="underline">Contact us</a>
                <a href="https://www.linkedin.com/company/origami-consulting">
                    <img src="assets/img/logos/linkedin.png" width="20" height="20" class="mt-2"
                         alt="Origami Consulting">
                </a>
                <a href="https://www.iubenda.com/privacy-policy/54124971"
                   class="font-400 text-white underline"
                   target="_blank"
                   title="Privacy Policy ">Privacy Policy</a>
            </div>
        </div>

        <div>
            <div class="hidden md:flex flex-col items-start leading-144 text-white">
                <span class="font-700">Origami Consulting</span>
                <span class="font-400 mt-[6px]">Via Catalani 75</span>
                <span class="font-400 mt-1">20131 Milano – Italia</span>
                <span class="font-400 mt-1">PI 04668050968</span>
            </div>
        </div>

    </div>
</div>
