<div class="fixed top-0 w-full bg-gray-700 pt-14 pb-2 z-120 transition-padding duration-500 ease-in-out" id="header">
    <div class="container">
        <div class="flex justify-between items-start">
            <a routerLink="/">
                <img src="assets/img/logos/mobile_logo.png"
                     width="324"
                     height="150"
                     id="origami_logo"
                     class="w-[162px] h-[75px] transition-all duration-500 ease-in-out"
                     alt="Origami Consulting">
            </a>

            <button id="menu"
                    class="flex sm:hidden menu-button mt-2">
                <div class="strip strip1"></div>
                <div class="strip strip2" id="strip2"></div>
                <div class="strip strip3"></div>
            </button>
        </div>
    </div>
</div>

