import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID, Renderer2, ViewEncapsulation, } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class HeaderComponent implements OnInit {
  locale: string;
  isMobileLayout: boolean = null;
  isServer: boolean = null;


  constructor(
    private renderer: Renderer2,
    @Inject(PLATFORM_ID) private platformId,
    @Inject(DOCUMENT) private document: Document,
  ) {
  }

  ngOnInit(): void {
    if(isPlatformServer(this.platformId)) {
      this.isServer = true;
    }
    if (isPlatformBrowser(this.platformId)) {
      this.isMobileLayout = window.innerWidth <= 768;
      window.onresize = () => this.isMobileLayout = window.innerWidth <= 768;
    }
  }


}
