<div class="fixed top-0 w-full z-120 text-16 text-black py-12 bg-gray-700 transition-padding duration-500 ease-in-out"
     id="header_desktop">
    <div class="container flex justify-between items-center">
        <a routerLink="/">
            <img src="assets/img/logos/logo.png"
                 width="364"
                 id="origami_logo_desktop"
                 height="168"
                 class="w-[182px] h-[84px] transition-all duration-500 ease-in-out" alt="Origami Consulting">
        </a>
        <div class="flex flex-row items-center space-x-7 text-white text-16 leading-200 font-700">
            <span routerLinkActive
                  #rla="routerLinkActive"
                  [routerLinkActiveOptions]="{exact: true}"
                  [ngClass]="{'text-gray-400': rla.isActive}">
               <a routerLink="/"
                  class="hover:underline">Home</a>
            </span>
            <span routerLinkActive
                  #rla1="routerLinkActive"
                  [routerLinkActiveOptions]="{exact: true}"
                  [ngClass]="{'text-gray-400': rla1.isActive}">
                  <a routerLink="/about"
                     class="hover:underline">About</a>
            </span>
            <span routerLinkActive
                  #rla2="routerLinkActive"
                  class="bg-gray-900 px-6 py-3 hover:bg-black rounded-2"
                  [routerLinkActiveOptions]="{exact: true}"
                  [ngClass]="{'bg-transparent border border-gray-900': rla2.isActive}">
                <a routerLink="/contact" class="w-full h-full">Contact us</a>
            </span>
        </div>
    </div>


</div>
