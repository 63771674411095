import { Component, HostListener, Inject, OnInit, PLATFORM_ID, Renderer2 } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { NavigationStart, Router } from '@angular/router';

@Component({
    selector: 'app-header-mobile',
    templateUrl: './header-mobile.component.html',
    styleUrls: ['./header-mobile.component.scss']
})
export class HeaderMobileComponent implements OnInit {

    semaphores: any = {
        menuVisible: false,
        menuTransition: false,
        disableButton: false,
        menuShrinked: false,
    };


    constructor(
        private router: Router,
        private renderer: Renderer2,
        @Inject(DOCUMENT) private document: Document,
        @Inject(PLATFORM_ID) private platformId) {
    }

    @HostListener('window:scroll') onWindowScroll(): void {
        if (isPlatformBrowser(this.platformId)) {
            const header = this.document.querySelector('#header') as HTMLElement;
            const logo = this.document.querySelector('#origami_logo') as HTMLElement;
            if (this.document.documentElement.scrollTop >= 140 && header.classList.contains('pt-14') && logo) {
                this.shrinkHeader(header, logo);
            } else if (this.document.documentElement.scrollTop < 140 && header.classList.contains('pt-2') && logo) {
                this.unshrinkHeader(header, logo);
            }
        }
    }


    ngOnInit(): void {
        this.router.events.subscribe(
            event => {
                if (event instanceof NavigationStart) {
                    this.closeMenuIfOpened();
                }
            }
        );
    }

    toggleMenuDialog(): void {
        if (this.semaphores.disableButton) {
            return;
        }
        if (isPlatformBrowser(this.platformId)) {
            this.document.getElementById('menu').classList.toggle('change');
            this.semaphores.menuTransition = !this.semaphores.menuTransition;
            if (this.semaphores.menuVisible) {
                this.semaphores.disableButton = true;
                setTimeout(() => {
                    this.semaphores.disableButton = false;
                    this.semaphores.menuVisible = false;
                    this.toggleOverflowClass();
                }, 1000);
            } else {
                this.semaphores.menuVisible = true;
                this.toggleOverflowClass();
            }
        }
    }

    toggleOverflowClass(): void {
        if (this.semaphores.menuVisible) {
            this.renderer.addClass(this.document.body, 'overflow-hidden');
        } else {
            this.renderer.removeClass(this.document.body, 'overflow-hidden');
        }
    }

    shrinkHeader(header: HTMLElement, logo: HTMLElement): void {
        header.classList.remove('pt-14');
        header.classList.add('pt-2');
        logo.style.width = '107px';
        logo.style.height = '50px';
        this.semaphores.menuShrinked = true;
    }

    unshrinkHeader(header: HTMLElement, logo: HTMLElement): void {
        header.classList.remove('pt-2');
        header.classList.add('pt-14');
        logo.style.width = '162px';
        logo.style.height = '75px';
        this.semaphores.menuShrinked = false;
    }

    closeMenuIfOpened(): void {
        if (isPlatformBrowser(this.platformId)) {
            this.semaphores.menuTransition = false;
            if (this.semaphores.menuVisible) {
                this.document.getElementById('menu').classList.toggle('change');
                this.semaphores.disableButton = true;
                setTimeout(() => {
                    this.semaphores.disableButton = false;
                    this.semaphores.menuVisible = false;
                    this.toggleOverflowClass();
                }, 1000);
            }
        }
    }


}
