import { Inject, Injectable, Injector, LOCALE_ID } from '@angular/core';
import { Resource } from '../../../Library/resource/resource.model';
import { DateTime } from 'luxon';
import { BaseAdapter } from '../../../Library/adapter/adapter.model';

export class ArticleSidebar extends Resource<Request> {
  public business_area?: string;
  public client?: string;
  public unit?: string;
  public team_members?: string;
  public website_link?: string;
  public application_link?: string;
  public application_link_label?: string;
  public employer?: string;
  public position?: string;
  public created_at?: string;
  public createdAtDate?: Date;
  public updated_at?: string;
  public updatedAtDate?: Date;
  public submit_deadline?: string;
  public deadlineDate?: Date;
  public start_date?: string;
  public startDate?: Date;
  public end_date?: string;
  public endDate?: Date;
  public start_time?: string;
  public location?: string;

  public constructor(data?: {}) {
    super(data);
  }
}

@Injectable({
  providedIn: 'root',
})
export class ArticleSidebarAdapter extends BaseAdapter<ArticleSidebar> {

  constructor(
    private injector: Injector,
    @Inject(LOCALE_ID) private localeCode: string,
  ) {
    super(ArticleSidebar);
  }

  adapt(data: any): ArticleSidebar {
    const item = super.adapt(data);

    if (item.created_at) {
      item.createdAtDate = DateTime.fromSQL(item.created_at, {zone: 'UTC'}).toJSDate();
    }

    if (item.updated_at) {
      item.updatedAtDate = DateTime.fromSQL(item.updated_at, {zone: 'UTC'}).toJSDate();
    }

    if (item.start_date) {
      item.startDate = DateTime.fromSQL(item.start_date, {zone: 'UTC'}).toJSDate();
    }

    if (item.end_date) {
      item.endDate = DateTime.fromSQL(item.end_date, {zone: 'UTC'}).toJSDate();
    }

    if (item.submit_deadline) {
      item.deadlineDate = DateTime.fromSQL(item.submit_deadline, {zone: 'UTC'}).toJSDate();
    }

    return item;
  }
}


