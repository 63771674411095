import { Injectable } from '@angular/core';
import { Resource as ResourceInterface } from '../../../Library/resource/resource';
import { Resource } from '../../../Library/resource/resource.model';
import { BaseAdapter } from '../../../Library/adapter/adapter.model';

export class Tag extends Resource<Request> implements ResourceInterface<Tag> {
  id?: number;
  name?: string;
  slug: string;
  is_hidden?: number;
  created_at?: string;
  updated_at?: string;

  constructor(data?: {}) {
    super(data);
    this.init();
  }

  init(): void {
  }
}

@Injectable({
  providedIn: 'root',
})
export class TagAdapter extends BaseAdapter<Tag> {
  constructor() {
    super(Tag);
  }
}

