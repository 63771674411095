<section class="w-full h-full bg-gray-100 text-gray-900">
    <div class="container flex flex-col justify-center items-center font-700">
        <span class="text-100 md:text-200 text-center">404</span>
        <span class="text-28 md:text-32 leading-120 text-center">The page has not been found</span>
        <span class="mt-4 font-400 text-22 md:max-w-[700px] text-center mb-26">
        Sorry, the content you were looking for cannot be found. Go back to
        <a routerLink="/" class="underline hover:cursor-pointer">homepage</a> .
    </span>
    </div>
</section>
