import { SeoItem } from '../../../Library/services/seo/seo-item.model';
import { environment } from '../../../../environments/environment';

export const seoRulesDefault = {
    me: new SeoItem({
        title: 'Origami Consulting -  traduzione documenti economico-finanziari a Milano',
        description: 'Il tuo partner ideale per tradurre documenti economico-finanziari, dall\'Italiano all\'Inglese. Più di 20 anni di esperienza al servizio delle migliori aziende.',
        seo_title: 'Origami Consulting -  traduzione documenti economico-finanziari a Milano',
        seo_fb_title: 'Origami Consulting -  traduzione documenti economico-finanziari a Milano',
        seo_fb_description: 'Il tuo partner ideale per tradurre documenti economico-finanziari, dall\'Italiano all\'Inglese. Più di 20 anni di esperienza al servizio delle migliori aziende.',
        seo_fb_image_url: environment.websiteUrl + '/assets/img/seo/seo_fb.jpg',
        seo_fb_type: 'website',
        seo_tw_title: 'Origami Consulting -  traduzione documenti economico-finanziari a Milano',
        seo_tw_description: 'Il tuo partner ideale per tradurre documenti economico-finanziari, dall\'Italiano all\'Inglese. Più di 20 anni di esperienza al servizio delle migliori aziende.',
        seo_tw_image_url: environment.websiteUrl + '/assets/img/seo/seo_tw.jpg',
        keywords: 'Origami Consulting,Origami Consulting Milano,Stuart McFarlane Brown,traduzione italiano-inglese a Milano,Chartered Accountant Milano,Chartered Accountant Italia,Chartered Accountant ITALY',
        url: environment.websiteUrl
    })
};

