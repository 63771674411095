export const routes: any = {
  me: {
    articles: 'clanci',
    articleDetail: 'clanak',
    contact: 'contact',
    page: '',
  },
  en: {
    articles: 'articles',
    articleDetail: 'article',
    contact: 'contact',
    page: '',
  },
};

export enum RouteName {
  HOME = 'homepage',
  ARTICLE_LIST = 'articles',
  ARTICLE_DETAIL = 'articleDetail',
  CUSTOM_PAGE = 'customPage',
  NOT_FOUND = 'notFound',
}

export let localeMapping: {} = {
  'sr-Latn-ME': 'me',
  'en-US': 'en',
  en: 'en',
};

export let currentLocale: string = $localize`:@@locale_code_sr_or_en|CurrentLocaleShortCode|CurrentLocaleShortCode:me`;
