import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../@shared/shared.module';

import { EnquiriesService } from '../../Library/contact/services/enquiries.service';
import { ReactiveFormsModule } from '@angular/forms';

import { ContentModuleDirective } from './directives/content-module.directive';
import { ContentModuleComponent } from './components/content-module.component';
import { ContentModuleA010Component } from './components/content-module-a010/content-module-a010.component';
import { ContentModuleA011Component } from './components/content-module-a011/content-module-a011.component';
import { ContentModuleA020Component } from './components/content-module-a020/content-module-a020.component';
import { ContentModuleA030Component } from './components/content-module-a030/content-module-a030.component';
import { ContentModuleA040Component } from './components/content-module-a040/content-module-a040.component';
import { ContentModuleA050Component } from './components/content-module-a050/content-module-a050.component';
import { ContentModuleA060Component } from './components/content-module-a060/content-module-a060.component';
import { ContentModuleA070Component } from './components/content-module-a070/content-module-a070.component';
import { ContentModuleA080Component } from './components/content-module-a080/content-module-a080.component';
import { ContentModuleA090Component } from './components/content-module-a090/content-module-a090.component';
import { ContentModuleB010Component } from './components/content-module-b010/content-module-b010.component';
import { ContentModuleB011Component } from './components/content-module-b011/content-module-b011.component';
import { ContentModuleB020Component } from './components/content-module-b020/content-module-b020.component';
import { ContentModuleB030Component } from './components/content-module-b030/content-module-b030.component';
import { SlotA10Component } from './components/slot-a10/slot-a10.component';
import { SlotA11Component } from './components/slot-a11/slot-a11.component';
import { SlotA20Component } from './components/slot-a20/slot-a20.component';
import { SlotA30Component } from './components/slot-a30/slot-a30.component';
import { SlotA40Component } from './components/slot-a40/slot-a40.component';
import { SlotA50Component } from './components/slot-a50/slot-a50.component';
import { SlotA60Component } from './components/slot-a60/slot-a60.component';
import { SlotA70Component } from './components/slot-a70/slot-a70.component';
import { SlotA80Component } from './components/slot-a80/slot-a80.component';
import { SlotA90Component } from './components/slot-a90/slot-a90.component';
import { SlotB10Component } from './components/slot-b10/slot-b10.component';
import { SlotB11Component } from './components/slot-b11/slot-b11.component';
import { SlotB20Component } from './components/slot-b20/slot-b20.component';
import { SlotB30Component } from './components/slot-b30/slot-b30.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    ContentModuleDirective,
    ContentModuleComponent,
    ContentModuleA010Component,
    ContentModuleA011Component,
    ContentModuleA020Component,
    ContentModuleA030Component,
    ContentModuleA040Component,
    ContentModuleA050Component,
    ContentModuleA060Component,
    ContentModuleA070Component,
    ContentModuleA080Component,
    ContentModuleA090Component,
    ContentModuleB010Component,
    ContentModuleB011Component,
    ContentModuleB020Component,
    ContentModuleB030Component,
    SlotA10Component,
    SlotA11Component,
    SlotA20Component,
    SlotA30Component,
    SlotA40Component,
    SlotA50Component,
    SlotA60Component,
    SlotA70Component,
    SlotA80Component,
    SlotA90Component,
    SlotB10Component,
    SlotB11Component,
    SlotB20Component,
    SlotB30Component,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    RouterModule,
  ],
  exports: [
    ContentModuleComponent,
  ],
  providers: [
    EnquiriesService,
  ],
})
export class ContentModuleModule {
  constructor() {
  }
}
