<div class="fixed top-0 w-full bg-gray-700 pt-14 pb-2 z-120 transition-padding duration-500 ease-in-out" id="header">
    <div class="container">
        <div class="flex justify-between items-start">
            <a routerLink="/">
                <img src="assets/img/logos/mobile_logo.png"
                     width="324"
                     height="150"
                     id="origami_logo"
                     class="w-[162px] h-[75px] transition-all duration-500 ease-in-out"
                     alt="Origami Consulting">
            </a>

            <button id="menu"
                    [disabled]="semaphores.disableButton"
                    (click)="toggleMenuDialog()"
                    class="flex sm:hidden menu-button mt-2">
                <div class="strip strip1"></div>
                <div class="strip strip2" id="strip2"></div>
                <div class="strip strip3"></div>
            </button>
        </div>
    </div>
    <div [ngClass]="{'h-menu top-[139px]': semaphores.menuTransition && !semaphores.menuShrinked,
                      'h-menu-shrinked top-[66px]': semaphores.menuTransition && semaphores.menuShrinked,
                       'h-0': !semaphores.menuTransition}"
         class="transition-height fixed w-full bg-gray-700 duration-1000 ease-in-out flex items-end
                justify-start text-white font-800 overflow-hidden">

        <div *ngIf="semaphores.menuVisible" class="container flex flex-col items-start">
            <div class="w-16/24 mr-8/24 border-b border-white pb-16 flex flex-col items-start container text-44 text-white leading-133 space-y-4">
                <a routerLink="/" class="hover:underline">Home</a>
                <a routerLink="/about" class="hover:underline">About</a>
                <a routerLink="/contact" class="hover:underline">Contact</a>
            </div>

            <div class="mt-4 mb-20 flex flex-col text-16 text-white">
                <span class="font-700">Origami consulting</span>
                <span class="font-400">Via Catalani 75</span>
                <span class="font-400">20131 Milano - Italia</span>
                <span class="font-400">PI 04668050968</span>
            </div>
        </div>


    </div>

</div>
